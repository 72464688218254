import { default as React } from "react"
import { ConfigurationPatch } from "../../api"

const SVG_VIEWBOX_SIZE = 308
const HEAD_CENTER = [0, 0]
const HEAD_RADIUS = 115
const IGNORE_EEG_LABELS = ["N/A", "BIAS"]

const adjustRadius = (x1: number, y1: number, r1: number, x2: number, y2: number, r2: number): number | null => {
  const d = Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)
  if (d > r1 + r2 || d < Math.abs(r1 - r2)) return null
  if (d === r1 + r2 || d === Math.abs(r1 - r2)) return null

  const r1_external = d - r2
  const r1_internal = Math.abs(d - r2)

  return Math.abs(r1 - r1_external) < Math.abs(r1 - r1_internal) ? r1_external : r1_internal
}

export const FlowLayoutSVG: React.FC<{flow_configuration: ConfigurationPatch}> = ({ flow_configuration }) => {
  const sourceLayout = flow_configuration.flow_layout_sources
  const eegLayout = flow_configuration.flow_layout_eeg
  const moduleCenters: Record<number, [number, number]> = {}
  for (const [moduleId, sources] of Object.entries(sourceLayout)) {
    // skip if any source is null
    if (sources.some(([x, y]) => x === null || y === null)) continue
    const x = sources.reduce((acc, [x, _]) => acc + (x || 0), 0) / sources.length // eslint-disable-line @typescript-eslint/no-unused-vars
    const y = sources.reduce((acc, [_, y]) => acc + (y || 0), 0) / sources.length // eslint-disable-line @typescript-eslint/no-unused-vars
    moduleCenters[parseInt(moduleId)] = [x, -y]
  }

  const minDistance = Math.min(
    ...Object.values(moduleCenters).map(([x1, y1]) => Math.min(
      ...Object.values(moduleCenters).filter(([x2, y2]) => x1 !== x2 || y1 !== y2).map(([x2, y2]) => Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2))
    ))
  )

  let radius = minDistance / 2

  for (const [x, y] of Object.values(moduleCenters)) {
    const newRadius = adjustRadius(x, y, radius, HEAD_CENTER[0], HEAD_CENTER[1], HEAD_RADIUS)
    if (newRadius !== null && newRadius < radius) {
      radius = newRadius
      radius -= 0.4
    }
  }

  const newModuleCenters = Object.fromEntries(
    Object.entries(moduleCenters).map(([moduleId, [x, y]]) => [parseInt(moduleId), [x + SVG_VIEWBOX_SIZE / 2, y + SVG_VIEWBOX_SIZE / 2]])
  )

  // add eeg if present
  let eeg = (eegLayout.filter(([label, x, y]) => !IGNORE_EEG_LABELS.includes(label) && x !== null && y !== null) || []) as [string, number, number][]
  eeg = eeg.map(([label, x, y]) => [label, x, -y])
  eeg = eeg.map(([label, x, y]) => [label, x + SVG_VIEWBOX_SIZE / 2, y + SVG_VIEWBOX_SIZE / 2])

  let eegRadius = radius
  for (const [_label, eegX, eegY] of eeg) { // eslint-disable-line @typescript-eslint/no-unused-vars
    for (const [moduleX, moduleY] of Object.values(newModuleCenters)) {
      const newRadius = adjustRadius(eegX, eegY, eegRadius, moduleX, moduleY, radius)
      if (newRadius !== null && newRadius < eegRadius) {
        eegRadius = newRadius
        eegRadius -= 0.4
      }
    }
  }

  const eegWidth = eegRadius * Math.sqrt(2)
  const eegHeight = eegWidth

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${SVG_VIEWBOX_SIZE} ${SVG_VIEWBOX_SIZE}`} xmlnsXlink="http://www.w3.org/1999/xlink">
      <g fontFamily="Calibri,Calibri_MSFontService,sans-serif" fontWeight={`${200+radius}`} fontSize={`${radius}`}>
      <circle cx={SVG_VIEWBOX_SIZE / 2} cy={SVG_VIEWBOX_SIZE / 2} r={HEAD_RADIUS} strokeWidth="0.6" strokeMiterlimit="6" stroke="#FFF" fill="none" />
      {Object.entries(newModuleCenters).map(([moduleId, [x, y]]) => (
        <circle
          className={`module-path module-${moduleId}`}
          key={moduleId}
          cx={x}
          cy={y}
          r={radius}
          stroke="#2F528F"
          strokeWidth="0.8"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
      ))}
      <g>
      {Object.entries(newModuleCenters).map(([moduleId, [x, y]]) => (
        <text className={`module-text module-${moduleId}`} key={moduleId} x={x} y={y + (radius / 4) + 1} textAnchor="middle">{moduleId}</text>
      ))}

      {eeg.map(([_label, x, y], i) => ( // eslint-disable-line @typescript-eslint/no-unused-vars
        <rect className={`eeg-path eeg-${i}`} key={i} x={x - eegWidth / 2} y={y - eegWidth / 2} width={eegWidth} height={eegHeight} stroke="#2F528F" fill="none" strokeWidth="0.8" strokeMiterlimit="8" />
      ))}
      </g>


      </g>

    </svg>
  )
}